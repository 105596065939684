import { Api } from "@/app/api";
import { Notification, NotificationResponse, UpdateNotificationDto } from "@/types";

export const NotificationApiInjection = Api.injectEndpoints({
	endpoints: (builder) => ({
		createNotification: builder.mutation<void, any>({
			query: (body) => ({
				url: "/v1/notifications",
				method: "POST",
				body: JSON.stringify(body),
				headers: {
					"Content-Type": "application/json",
				},
			}),
		}),

		getNotifications: builder.query<NotificationResponse, void>({
			query: () => ({
				url: "/v1/notifications",
				headers: {
					"Content-Type": "application/json",
				},
				params: {
					pagination: false,
				},
			}),
		}),

		getNotification: builder.query<Notification, string>({
			query: (id) => ({
				url: `/v1/notifications/${id}`,
				headers: {
					"Content-Type": "application/json",
				},
			}),
		}),
		searchNotifications: builder.query<
			{ docs: Notification[] },
			{ searchQuery?: string; sortBy?: string; page: number; limit: number }
		>({
			query: ({ searchQuery, sortBy, page, limit }) => ({
				url: `/v1/notifications`,
				params: {
					page: page,
					limit: limit,
					search: searchQuery,
					sortBy: sortBy,
				},
				headers: {
					"Content-Type": "application/json",
				},
			}),
		}),
		deleteNotification: builder.mutation<void, string>({
			query: (id) => ({
				url: `/v1/notifications/${id}`,
				method: "DELETE",
			}),
		}),

		updateNotification: builder.mutation<void, { id: string; body: UpdateNotificationDto }>({
			query: ({ id, body }) => ({
				url: `/v1/notifications/${id}`,
				method: "PATCH",
				body: JSON.stringify(body),
				headers: {
					"Content-Type": "application/json",
				},
			}),
		}),
	}),
});

export const {
	useCreateNotificationMutation,
	useGetNotificationQuery,
	useGetNotificationsQuery,
	useSearchNotificationsQuery,
	useUpdateNotificationMutation,
	useDeleteNotificationMutation,
} = NotificationApiInjection;
