"use client";

import Link from "next/link";
import type { Session } from "next-auth";
import type { MouseEventHandler } from "react";

import { getInitials, isUserAdmin } from "@/lib/utils";
import { useDelayedToggleState, useWindowResizeSizing } from "@/lib/hooks";

import AvatarIcon from "@/components/Avatar";
import NavDropdownItem from "@/app/components/Navigation/components/NavDropdownItem";

import logoutIcon from "/public/assets/profile/logout.png";
import inboxIcon from "/public/assets/profile/inbox.png";
import orderIcon from "/public/assets/profile/order.png";
import savedIcon from "/public/assets/profile/saved.png";
import settingsIcon from "/public/assets/profile/settings.png";
import preferencesIcon from "/public/assets/profile/preferences.png";
import affiliatesIcon from "/public/assets/profile/affiliates.png";

type Props = { session: Session | null };
export default function NavProfileButton({ session }: Props) {
	const dropdownMaxHeight = useWindowResizeSizing(0, -100);
	const [hovered, showDropdown, setDropdownVisibility] = useDelayedToggleState(false, 300);

	const setHoverStatus: MouseEventHandler<HTMLDivElement> = (e) => {
		const show = e.type === "mouseenter";
		const root = document.body;
		root.style.overflow = show ? "hidden" : "auto";
		root.style.paddingRight = show ? "0.25rem" : "0";

		setDropdownVisibility(show);
	};

	if (session === null) {
		return (
			<Link
				href="/login"
				className="ml-4 flex w-12 items-center justify-center font-semibold"
			>
				Login
			</Link>
		);
	}

	const isAdmin = isUserAdmin(session.user.role);

	return (
		<div
			className="group relative ml-4"
			onMouseEnter={setHoverStatus}
			onMouseLeave={setHoverStatus}
		>
			<button
				role="combobox"
				aria-controls="profile-dropdown"
				aria-expanded={hovered}
				className="flex size-12 items-center justify-center"
			>
				<AvatarIcon
					avatarUrl={session.user.avatar}
					fallback={getInitials(session.user.firstName, session.user.lastName)}
				/>
			</button>
			{showDropdown && (
				<div
					id="profile-dropdown"
					className="absolute right-0 top-5 flex max-w-72 flex-col opacity-0 duration-300 group-hover:opacity-100"
				>
					<div aria-hidden className="h-12" />
					<ul
						role="group"
						className="grid w-full overflow-y-auto overflow-x-hidden border border-secondary/10 bg-background shadow-md"
						style={{ maxHeight: `${dropdownMaxHeight.current}px` }}
					>
						<li className="flex min-h-16 flex-col p-4 pb-2 leading-snug">
							Hello, {session.user.firstName} {session.user.lastName}
							<p className="text-sm text-grey-foreground">{session.user.email}</p>
						</li>
						<NavDropdownItem title="Inbox" href="/profile/inbox" image={inboxIcon} />
						<NavDropdownItem
							title="Orders"
							href="/profile/order-history"
							image={orderIcon}
						/>
						<NavDropdownItem title="Saved" href="/profile/saved" image={savedIcon} />
						<NavDropdownItem
							title="Preferences"
							href="/profile/preferences"
							image={preferencesIcon}
						/>
						<NavDropdownItem
							title="Settings"
							href="/profile/settings"
							image={settingsIcon}
						/>
						<NavDropdownItem
							title={isAdmin ? "Admin Affiliates" : "Affiliates"}
							href={isAdmin ? "/admin/affiliates" : "/profile/affiliates"}
							image={affiliatesIcon}
						/>
						{isAdmin && (
							<NavDropdownItem
								title="Admin Panel"
								href="/admin/dashboard"
								image={settingsIcon}
							/>
						)}
						<NavDropdownItem title="Logout" href="/profile/logout" image={logoutIcon} />
					</ul>
				</div>
			)}
		</div>
	);
}
