"use client";

import Image, { StaticImageData } from "next/image";
import type { Session } from "next-auth";
import { MouseEventHandler, useMemo, useRef } from "react";

import { cn } from "@/lib/utils";
import type { Notification, NotificationResponse } from "@/types";
import { useDelayedToggleState, useOutOfBoundsHitToggle, useWindowResizeSizing } from "@/lib/hooks";
import {
	useGetNotificationsQuery,
	useUpdateNotificationMutation,
} from "@/features/notifications/notificationApiInjection";

import { useToast } from "@/components/ui/use-toast";
import LoadingSpinner from "@/components/loadingComponents/loadingSpinner";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

import notificationIcon from "/public/assets/home/navigation/notification.png";
import notificationUnreadIcon from "/public/assets/home/navigation/notification-unread.png";
import notificationClickedIcon from "/public/assets/home/navigation/clicked/notification.png";

type Props = { session: Session | null };
export default function NavNotificationButton({ session }: Props) {
	const wrapperRef = useRef<HTMLDivElement | null>(null);
	const [clicked, showDropdown, setDropdownVisibility] = useDelayedToggleState(false, 300);

	useOutOfBoundsHitToggle(wrapperRef, [clicked, setDropdownVisibility]);

	const { data, isLoading, refetch } = useGetNotificationsQuery(undefined, {
		skip: session === null,
	});
	const unreadNotifications = useMemo(() => data?.docs.filter((x) => !x.isRead) ?? [], [data]);

	if (session === null) {
		return <></>;
	}

	return (
		<div ref={wrapperRef} className="group sm:relative">
			<button
				role="combobox"
				aria-controls="notification-dropdown"
				aria-expanded={showDropdown}
				aria-label="Notifications"
				className="relative flex size-10 items-center justify-center"
				onClick={() => setDropdownVisibility((old) => !old)}
			>
				<NotificationIcon isOpened={clicked} hasUnread={unreadNotifications.length !== 0} />

				{unreadNotifications.length > 0 && (
					<div className="absolute right-1 top-0.5 inline-flex size-4 items-center justify-center rounded-full bg-tertiary text-xxsm text-secondary-foreground">
						<span>{unreadNotifications.length}</span>
					</div>
				)}
			</button>
			{showDropdown && (
				<div
					id="notification-dropdown"
					className={cn(
						"absolute right-0 top-[90px] w-screen opacity-0 duration-300 sm:-right-2 sm:top-16 sm:w-96",
						{ "opacity-100": clicked },
					)}
				>
					<NotificationDropdown
						data={data}
						unreadNotifications={unreadNotifications}
						isLoading={isLoading}
						refresh={refetch}
					/>
				</div>
			)}
		</div>
	);
}

function NotificationIcon({ hasUnread, isOpened }: { hasUnread: boolean; isOpened: boolean }) {
	if (isOpened) {
		return (
			<Image
				src={notificationClickedIcon}
				alt=""
				aria-hidden
				sizes="10vw"
				className="w-[2.15rem]"
			/>
		);
	}
	if (hasUnread) {
		return (
			<Image src={notificationUnreadIcon} alt="" aria-hidden sizes="10vw" className="w-7" />
		);
	}

	return <Image src={notificationIcon} alt="" aria-hidden sizes="10vw" className="w-7" />;
}

type NotificationDropdownProps = {
	data?: NotificationResponse;
	unreadNotifications: Notification[];
	isLoading: boolean;
	refresh: () => void;
};
function NotificationDropdown({
	data,
	unreadNotifications,
	isLoading,
	refresh,
}: NotificationDropdownProps) {
	const { toast } = useToast();
	const dropdownMaxHeight = useWindowResizeSizing(0, -100);
	const [updateNotification, { isLoading: isUpdating }] = useUpdateNotificationMutation();

	const baseClass = cn(
		"flex min-h-32 flex-col overflow-y-auto border border-secondary/10 bg-background transition-opacity duration-300",
		{ "items-center justify-center h-32": isLoading, "opacity-75": isUpdating },
	);

	const onNotificationClick = async (id: string, readStatus = true) => {
		const updated = await updateNotification({
			id: id,
			body: { isRead: readStatus },
		});

		if ("error" in updated) {
			toast({
				title: "Failed to update the seen status",
				description: updated.error.toString(),
				variant: "destructive",
			});
			return;
		}

		refresh();
	};

	if (isLoading) {
		return (
			<div className={baseClass}>
				<LoadingSpinner />
			</div>
		);
	}

	return (
		<Tabs
			defaultValue="all"
			className={baseClass}
			style={{ maxHeight: `${dropdownMaxHeight.current}px` }}
		>
			<TabsList className="mx-auto mt-4">
				<TabsTrigger value="all" className="w-24">
					All
				</TabsTrigger>
				<TabsTrigger value="unread" className="w-24">
					Unread
				</TabsTrigger>
			</TabsList>

			<TabsContent role="list" value="all" className="overflow-y-auto">
				{data === undefined || data.docs.length === 0 ? (
					<NotificationEmptyRow />
				) : (
					data?.docs.map((x) => (
						<NotificationRow
							key={x._id ?? x.content}
							data={x}
							onClick={() => onNotificationClick(x._id!, !x.isRead)}
						/>
					))
				)}
			</TabsContent>

			<TabsContent role="list" value="unread">
				{unreadNotifications.length === 0 ? (
					<NotificationEmptyRow />
				) : (
					unreadNotifications.map((x) => (
						<NotificationRow
							key={x._id ?? x.content}
							data={x}
							onClick={() => onNotificationClick(x._id!)}
						/>
					))
				)}
			</TabsContent>

			{/* <div className="flex h-10 min-h-10 flex-1 items-center justify-end border-t border-secondary/10 px-2">
				<Link href="/settings">
					<Image src={settingsIcon} alt="Settings" className="size-7" />
				</Link>
			</div> */}
		</Tabs>
	);
}

type NotificationRowProps = { data: Notification; onClick: MouseEventHandler<HTMLButtonElement> };
function NotificationRow({ data, onClick }: NotificationRowProps) {
	return (
		<button
			role="listitem"
			onClick={onClick}
			className={cn("grid grid-cols-[3rem_1fr] gap-4 bg-secondary/10 p-4", {
				"bg-background": data.isRead,
			})}
		>
			<div
				aria-label={data.isRead ? "Read" : "Unread"}
				className="flex size-10 items-center justify-center justify-self-center rounded-full bg-accent-foreground/50"
			>
				<Image
					src={notificationUnreadIcon}
					alt=""
					aria-hidden
					sizes="10vw"
					className="w-6"
				/>
			</div>

			<span className="place-self-center text-start text-sm font-light leading-tight">
				{data.content}
			</span>
		</button>
	);
}

function NotificationEmptyRow() {
	return <span className="mx-auto block py-4 text-center">You are all caught up</span>;
}
