import clsx from "clsx";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

type Props = {
	avatarUrl?: string;
	fallback: string;
	border?: boolean;
};

function AvatarIcon({ avatarUrl, fallback, border = true }: Props) {
	return (
		<div
			className={clsx(
				"flex w-full items-center justify-center rounded-[50%] text-base font-semibold",
				{ "border-2 border-tertiary": border },
			)}
		>
			<Avatar className="aspect-square size-full">
				<AvatarImage src={avatarUrl} alt="avatar" className="w-full border-accent" />
				<AvatarFallback className="w-full bg-secondary-foreground">
					{fallback}
				</AvatarFallback>
			</Avatar>
		</div>
	);
}

export default AvatarIcon;
