import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/home/downloadApp/apple-green.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/home/downloadApp/google-green.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/home/footer/socialMedia/facebook.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/home/footer/socialMedia/ig.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/home/footer/socialMedia/linkedIn.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/home/footer/socialMedia/tiktok.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/home/footer/socialMedia/twitter.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/home/footer/socialMedia/youtube.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/logo.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/Navigation/components/NavBasketButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/Navigation/components/NavMobileMenuButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/Navigation/components/NavNotificationButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/Navigation/components/NavProfileButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/Navigation/components/NavServicesButton.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/separator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/lib/provider.tsx");
