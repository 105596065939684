import Link from "next/link";
import Image, { type StaticImageData } from "next/image";

type Props = { title: string; href: string; image: string | StaticImageData };
export default function NavDropdownItem({ title, href, image: logo }: Props) {
	return (
		<li>
			<Link
				href={href}
				className="flex h-14 items-center gap-4 px-4 font-light text-foreground transition-colors hover:bg-secondary/[0.04] hover:text-secondary"
			>
				<Image
					src={logo}
					alt=""
					aria-hidden
					width={100}
					height={75}
					quality={100}
					className="w-7"
					draggable={false}
				/>
				<span className="pointer-events-none">{title}</span>
			</Link>
		</li>
	);
}
